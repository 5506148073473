@charset "utf-8";

html,
body,
#wrap {
	height: 100%;
	width: 100%;
}

body {
	line-height: 1 !important;
}

#wrap {
	position: relative;
	min-width: 1400px;
	width: 100%;
	height: 100%;
	padding-top: 46px;
	background-color: var(--color-sub2);
	/* overflow:hidden;overflow-y:auto; */
}

/* 아이콘 모음 */
.logo {
	display: inline-block;
	width: 136px;
	height: 22px;
	margin: 11px 0 0 56px;
	background: url(../../public/images/logo.png) center no-repeat;
	text-indent: -9999px;
}

.logo_txt {
	font-size: 21px;
	font-weight: 700;
	color: var(--color-white);
}

.icon_heart {
	width: 20px;
	height: 17px;
	background: url(../../public/images/icon_heart.png) center no-repeat;
	text-indent: -9999px;
}

.icon_cart {
	width: 20px;
	height: 20px;
	background: url(../../public/images/icon_cart.png) center no-repeat;
	text-indent: -9999px;
}

.icon_share {
	width: 20px;
	height: 22px;
	background: url(../../public/images/icon_share.png) center no-repeat;
	text-indent: -9999px;
}

.login_off {
	width: 22px;
	height: 22px;
	background: url(../../public/images/icon_login_off.png) center no-repeat;
	text-indent: -9999px;
}

.admin {
	width: 24px;
	height: 21px;
	background: url(../../public/images/admin_b.png) center no-repeat;
	text-indent: -9999px;
}

.logout {
	width: 20px;
	height: 24px;
	background: url(../../public/images/icon_logout.png) center no-repeat;
	text-indent: -9999px;
}

.icon_search_w {
	width: 21px;
	height: 20px;
	background: url(../../public/images/icon_search_w.png) center no-repeat;
	text-indent: -9999px;
}

.icon_youTube {
	width: 24px;
	height: 17px;
	background: url(../../public/images/icon_youTube.png) center no-repeat;
	text-indent: -9999px;
}

.icon_soundCloud {
	width: 29px;
	height: 13px;
	background: url(../../public/images/icon_soundCloud.png) center no-repeat;
	text-indent: -9999px;
}

.icon_instagram {
	width: 21px;
	height: 21px;
	background: url(../../public/images/icon_instagram.png) center no-repeat;
	text-indent: -9999px;
}

.kakao_login {
	background-image: url(../../public/images/icon_kakao_w.svg);
}

.kakao_login.possible {
	background-color: #fae300;
	background-image: url(../../public/images/icon_kakao.svg);
}

.naver_login {
	background-image: url(../../public/images/icon_naver_w.svg);
}

.naver_login.possible {
	background-color: #00c300;
}

.google_login {
	background-image: url(../../public/images/icon_google_w.svg);
}

.google_login.possible {
	background-color: var(--color-white);
	background-image: url(../../public/images/icon_google.svg);
	border: 1px solid var(--color-lightgrey);
}

.url_copy {
	text-indent: inherit !important;
	border: 1px solid #d9d9d9;
	line-height: 1.2 !important;
	background: 0 none !important;
	color: var(--color-video) !important;
}

.unlind {
	text-decoration: underline;
}

.color_green {
	color: var(--color-video);
}

.MAR7 {
	margin-right: 7px !important;
}

.error_txt {
	color: var(--color-warning);
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	margin: 8px 0 0;
}

/* header */
#header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 46px;
	padding: 0;
	background-color: var(--color-sub2);
	z-index: 1002;
}

#header .icon_line_box {
	position: absolute;
	top: 14px;
	left: 18px;
	width: 20px;
	height: 18px;
}

#header .icon_line_box span {
	position: absolute;
	left: 0;
	height: 2px;
	background-color: var(--color-white);
}

#header .icon_line_box span:first-child {
	top: 0;
	width: 20px;
}

#header .icon_line_box span:nth-child(2) {
	top: 8px;
	width: 13px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

#header .icon_line_box span:last-child {
	bottom: 0;
	width: 20px;
}

#header .icon_line_box.open span:nth-child(2) {
	width: 20px;
}

#header .top_nav {
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	line-height: 46px;
	font-size: 0;
}

#header .top_nav a {
	display: inline-block;
	position: relative;
	padding: 0 25px;
	height: 100%;
	font-size: 16px;
	font-weight: 700;
	color: var(--color-white);
}

#header .top_nav a::before,
#header .top_nav .active::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 6px;
}

#header .top_nav a::before {
	left: 50%;
	width: 0;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

#header .top_nav a:hover::before {
	left: 0;
	width: 100%;
}

#header .header_btns {
	position: absolute;
	top: 0;
	right: 16px;
}

#header .header_btns button:last-child {
	margin-right: 0;
}

#header .header_btns .admin,
#header .header_btns .icon_heart,
#header .header_btns .icon_cart,
#header .header_btns .login_off,
#header .header_btns .icon_search_w,
#header .header_btns .logout {
	height: 46px;
	margin-right: 15px;
	line-height: 46px;
}

#header .header_btns .icon_search_w,
#header .header_btns .logout {
	margin-left: -7px;
}

#header .header_btns .login_on {
	width: 24px;
	height: 24px;
	margin-top: 11px;
	margin-right: 20px;
	border-radius: 100%;
	overflow: hidden;
}

#header .header_btns .login_on img {
	width: 100%;
	height: 100%;
}

#header .header_btns button.text_btn {
	display: inline-block;
	width: auto;
	margin-right: 28px;
	line-height: 46px;
	color: var(--color-white);
	font-size: 16px;
	font-weight: 400;
	text-indent: inherit;
	background: none;
	letter-spacing: 0.5px;
}

#expand_menu {
	display: none;
	position: fixed;
	top: 46px;
	left: 0;
	width: 100%;
	height: auto;
	padding: 0;
	background-color: var(--color-sub-alpha90);
	z-index: 1002;
}

#expand_menu a {
	display: block;
	color: var(--color-midgrey);
	font-weight: 300;
}

#expand_menu a:hover,
#expand_menu .expand_1depth>li:hover>a {
	color: var(--color-white);
	font-weight: 600 !important;
	letter-spacing: -0.5px;
}

#expand_menu .expand_1depth {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 34px 0 30px;
}

#expand_menu .expand_1depth li {
	margin: 0 24px;
}

#expand_menu .expand_1depth li a {
	font-size: 20px;
}

#expand_menu .expand_2depth {
	margin-top: 23px;
}

#expand_menu .expand_2depth li {
	margin: 0;
}

#expand_menu .expand_2depth li a {
	position: relative;
	padding: 9px 0 10px 17px;
	font-size: 14px;
	color: var(--color-white);
}

#expand_menu .expand_2depth li a::before {
	content: '';
	display: block;
	position: absolute;
	top: 15px;
	left: 0;
	width: 8px;
	height: 3px;
	background: var(--color-darkgrey);
}

#expand_menu .expand_2depth li a:hover {
	letter-spacing: 0;
}

.expand_menu_open #header .top_nav .active::before,
.expand_menu_open #header .top_nav a:hover::before {
	left: 50%;
	width: 0;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

/* 상단 검색 영역 */
#search_area {
	position: relative;
	width: 100%;
	height: 400px;
	background-color: var(--color-main-bg);
	/* margin-top:46px */
}

#search_area .video_bg {
	position: relative;
	width: 100%;
	height: 100%;
	/* padding-top:56.25%; */
	overflow: hidden;
}

#search_area .video_bg::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 120px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
	z-index: 1;
}

#search_area .video_bg video,
#search_area .video_bg img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

/* youbute 영상 */
.youtube .youtube_area {
	width: 100%;
	position: absolute;
	left: 50%;
	margin-left: calc(100% / -2);
	top: 50%;
	margin-top: calc(100% * 9 / 16 / -2);
}

/* .youtube의 가운데 배치 */
#player {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.youtube .youtube_area::before {
	content: '';
	display: block;
	width: 100%;
	height: 0;
	padding-top: 56.25%;
}

/* 16:9 비율로 영상 출력. */
.youtube .youtube_cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*background-color:rgba(0, 0, 0, .3);*/
}

#search_area .search_box {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

#search_area .search_box .search_input {
	position: relative;
	width: 747px;
	height: 56px;
	margin: 0 auto;
}

#search_area .search_box .search_input .input {
	width: 100%;
	height: 100%;
}

#search_area .search_box .search_input .input input {
	width: 100%;
	height: 100%;
	padding: 18px 72px 18px 24px;
	color: var(--color-midgrey);
	background-color: var(--color-white);
	border-radius: 28px;
	border: 0 none;
}

#search_area .search_box .search_input .input input::placeholder {
	color: var(--color-midgrey);
	letter-spacing: 0;
}

#search_area .search_box .search_input .submit {
	position: absolute;
	right: 0;
	top: 0;
	width: 70px;
	height: 100%;
}

#search_area .search_box .search_input .submit button {
	width: 100%;
	height: 100%;
	background: url(../../public/images/icon_search.png) center no-repeat;
	text-indent: -9999px;
}

/* 검색 상세 페이지 */
#search_area.search_page {
	height: 145px;
	border-bottom: 1px solid #51535d;
}

#search_area.search_page .search_history {
	width: 570px;
	margin: 8px auto 0;
}

#search_area.search_page .search_history .list {
	background: var(--color-sub-alpha70);
	border: 0 none;
}

#search_area.search_page .search_box .search_input {
	width: 570px;
	height: 40px;
}

#search_area.search_page .search_box .search_input .input input {
	color: var(--color-lightgrey);
	background-color: var(--color-sub2);
}

#search_area.search_page .search_box .search_input .input input::placeholder {
	color: var(--color-lightgrey);
}

#container {
	background: var(--color-main-bg);
}

/* themes_menu */
#container .main_content {
	display: flex;
	min-height: 900px;
}

.main_content .themes_menu {
	display: flex;
	margin: 8px 0;
}

/* themes_1depth */
.themes_menu .themes_1depth {
	flex-basis: 194px;
	width: 194px;
	padding: 18px 32px;
}

.themes_menu .themes_1depth strong {
	display: block;
	width: 100%;
	font-size: 18px;
	font-weight: 700;
	color: var(--color-midgrey);
}

.themes_menu .themes_1depth button {
	display: block;
	width: 100%;
	margin-top: 35px;
	color: var(--color-midgrey);
	text-align: left;
}

.themes_menu .themes_1depth button.active {
	font-weight: 700;
	color: var(--color-video);
	text-decoration: underline;
}

.themes_menu .themes_1depth {}

/* themes_2depth */
.themes_menu .themes_2depth {
	position: relative;
	flex-basis: 0;
	width: 0;
	padding: 0;
	transition: all 0.4s linear;
	overflow: hidden;
}

.themes_menu .themes_2depth .cont_2depth {
	padding: 0 24px 20px 40px;
	flex-basis: 270px;
	width: 270px;
}

.themes_menu.themes_menu_open .themes_2depth {
	flex-basis: 270px;
	width: 270px;
}

.themes_menu.themes_menu_open .themes_2depth::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 1px;
	height: 100%;
	background: rgba(255, 255, 255, 0.1);
}

.themes_menu.themes_menu_open .themes_2depth_list {
	/* opacity:1;transition:all .4s linear; */
}

.themes_menu .themes_2depth_close {
	display: block;
	width: 14px;
	height: 14px;
	margin: 19px 0 19px auto;
	background: url(../../public/images/icon_themes_close.png) center no-repeat;
	text-indent: -9999px;
}

.themes_menu .themes_2depth_list {
	/* opacity:0;transition:all .2s linear; */
}

.themes_menu .themes_2depth_list button {
	position: relative;
	display: block;
	width: 100%;
	margin-top: 7px;
	padding: 17px 15px 17px 0;
	font-size: 15px;
	color: var(--color-midgrey);
	text-align: left;
}

.themes_menu .themes_2depth_list button::after {
	content: '';
	display: block;
	position: absolute;
	right: 2px;
	top: 18px;
	width: 12px;
	height: 12px;
	background: url(../../public/images/icon_themes_plus.png) center no-repeat;
}

.themes_menu .themes_2depth_list button.active {
	padding: 17px 15px 17px 23px;
	font-weight: 700;
	color: var(--color-video);
}

.themes_menu .themes_2depth_list button.active::before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 18px;
	width: 7px;
	height: 11px;
	background: url(../../public/images/icon_themes_arrow.png) center no-repeat;
}

.themes_menu .themes_2depth_list button.active::after {
	background: url(../../public/images/icon_themes_minus.png) center no-repeat;
}

/* search_history */
.themes_cont {
	position: relative;
	left: 0;
	width: 100%;
	padding: 8px 0;
	transition: all 0.8s linear;
}

.search_history {
	font-size: 0;
	margin-bottom: 12px;
}

.search_history .list {
	position: relative;
	position: relative;
	display: inline-block;
	margin: 4px 8px 0px 0;
	padding: 8px 35px 8px 16px;
	background: var(--color-sub-alpha70);
	border-radius: 28px;
}

.search_history .list span {
	color: var(--color-white);
	font-size: 16px;
	font-weight: 700;
}

.search_history .list .del {
	width: 9px;
	height: 10px;
	position: absolute;
	right: 16px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background: url(../../public/images/icon_del.png) center no-repeat;
}

.search_history .list+.list_clear {
	margin-left: 16px;
}

.search_history .list_clear {
	display: inline-block;
	line-height: 22px;
}

.search_history .list_clear .clear {
	color: var(--color-midgrey);
	font-size: 14px;
	font-weight: 700;
}

/* main_list */
.cont_list.main_list .list {
	float: left;
	flex-basis: calc(33.333% - 5.4px);
}

/* 검색 상세 */
.search_content {
	position: relative;
	width: 1400px;
	margin: 0 auto;
	padding: 57px 0 37px;
	min-height: 600px;
}

.search_content .search_history {
	margin-left: 0;
}

.search_content .search_history .list {
	background: transparent;
	border: 1px solid var(--color-white-alpha20) !important;
}

.search_content .search_history .list.on {
	background-color: #273082;
	border: 1px solid #4454e0;
}

.search_content .search_history .list span {
	font-weight: 400;
	color: var(--color-grey);
}

.search_content .search_history .list.on span {
	font-weight: 700;
	color: var(--color-white);
}

.search_content .search_history .list .del {
	right: 10px;
	padding: 10px;
}

.search_content .search_history .list_clear .clear {
	text-decoration: underline;
}

.search_content .cont_list {
	gap: 28px 28px;
	margin-top: 16px;
}

.search_content .cont_list .list {
	flex-basis: calc(25% - 21px);
}

/* footer */
#footer {
	position: relative;
	padding: 31px 40px;
	background-color: var(--color-sub2);
}

#footer .inner {
	position: relative;
	width: 100%;
}

#footer .link_box {
	position: absolute;
	top: 19px;
	right: 0;
}

#footer .link_box::after {
	content: '';
	display: block;
	clear: both;
}

#footer .link_box a {
	margin-right: 27px;
	font-size: 16px;
	color: var(--color-white);
}

#footer .link_box a:last-child {
	margin-right: 0;
}

#footer .link_box a:hover {
	font-weight: 700;
}

#footer .link_box .left {
	float: left;
}

#footer .link_box .right {
	float: right;
}

#footer .logo {
	margin: 0;
}

#footer .info {
	display: inline-block;
}

#footer .info_top {
	margin-top: 24px;
	font-size: 16px;
	color: var(--color-white);
	font-weight: 400;
	line-height: 24px;
}

#footer .info_btm {
	margin-top: 8px;
	font-size: 14px;
	color: var(--color-grey);
	font-weight: 400;
	line-height: 18px;
}

#footer .info_top span+span::before,
#footer .info_btm span+span::before {
	content: '|';
	color: var(--color-grey);
	margin: 0 4px;
}

#footer .info_btm span+span::before {
	margin: 0 3px;
}

#footer .btn_box {
	position: absolute;
	bottom: 9px;
	right: 0;
	display: inline-block;
}

#footer .btn_box button {
	width: 40px;
	height: 40px;
	border-radius: 28px;
	background-color: #d9d9d9;
}

#footer .btn_box button+button {
	margin-left: 12px;
}

/* layerpop 공통 */
.layerpop {
	visibility: hidden;
	overflow: hidden;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	z-index: -1;
	background-color: rgba(0, 0, 0, 0.1);
}

.layerpop.open {
	visibility: visible;
	overflow: visible;
	opacity: 1;
	z-index: 9999;
}

.layerpop .layerpop_area {
	width: 730px;
	overflow: hidden;
	background: var(--color-white);
	border-radius: 4px;
}

.layerpop .title {
	position: relative;
	height: 85px;
	padding: 32px 65px 0 32px;
	background-color: var(--color-white);
}

.layerpop .title::before {
	content: '';
	display: block;
	clear: both;
	position: absolute;
	bottom: 0;
	left: 32px;
	width: calc(100% - 64px);
	height: 1px;
	background: var(--color-lightgrey2);
}

.layerpop .title strong {
	display: block;
	color: var(--color-deepgrey);
	font-size: 24px;
	font-weight: 700;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.layerpop .close {
	position: absolute;
	right: 30px;
	top: 28px;
	width: 30px;
	height: 30px;
	text-indent: -9999px;
	background: url(../../public/images/icon_pop_close.png) center no-repeat;
}

.layerpop .close::before {
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	line-height: 30px;
	text-indent: 0;
	font-size: 24px;
	color: #171b27;
}

.layerpop .content {
	position: relative;
	width: 100%;
	padding: 32px;
	background-color: var(--color-white);
}

.layerpop .content .cont_tit {
	line-height: 1.45;
	font-size: 20px;
	font-weight: 700;
	color: var(--color-darkgrey);
	text-align: center;
}

.layerpop .content .cont_txt {
	line-height: 1.45;
	font-size: 18px;
	color: var(--color-grey);
	text-align: center;
}

.layerpop .content .cont_txt_s {
	line-height: 1.45;
	font-size: 16px;
	color: var(--color-grey);
	text-align: center;
}

.layerpop .content .cont_txt_bold {
	line-height: 1.45;
	font-size: 18px;
	font-weight: 700;
	color: var(--color-darkgrey);
	text-align: center;
}

.layerpop .next_btn {
	position: relative;
	padding: 0 32px 32px;
	font-size: 0;
	background-color: var(--color-white);
	text-align: center;
}

.board_btns .green_btn,
.layerpop .next_btn button {
	display: inline-block;
	height: auto;
	line-height: inherit;
	min-width: 208px;
	padding: 16px;
	margin: 0 4px;
	font-size: 20px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.8);
	background-color: var(--color-video);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 4px;
}

.board_btns .green_btn {
	min-width: 222px;
	padding: 18px;
	font-size: 18px;
	color: var(--color-white);
	font-weight: 500;
	border-color: var(--color-video);
}

.layerpop .next_btn button.disabled,
.layerpop .next_btn button[disabled] {
	color: var(--color-midgrey2);
	border: 1px solid var(--color-lightgrey);
	background-color: var(--color-lightgrey2);
	opacity: 1;
}

.layerpop .next_btn button.type2 {
	color: var(--color-grey);
	border-color: var(--color-lightgrey);
	background-color: var(--color-white) !important;
}

.layerpop .next_btn.border_top {
	padding-top: 24px;
}

.layerpop .next_btn.border_top::before {
	content: '';
	display: block;
	clear: both;
	position: absolute;
	top: 0;
	left: 32px;
	width: calc(100% - 64px);
	height: 1px;
	background: var(--color-lightgrey2);
}

.layerpop .find_btn {
	margin: 4px 0 18px;
	text-align: center;
}

.layerpop .find_btn button {
	font-size: 18px;
	text-decoration: underline;
	color: var(--color-grey);
}

/* 레이어 팝업 빠른 로그인 */
.sns_login_box {
	font-size: 0;
	margin: 40px 0;
	text-align: center;
}

.sns_login_box button {
	display: inline-block;
	width: 64px;
	height: 64px;
	margin: 0 12px;
	background-color: #d9d9d9;
	border-radius: 50px;
	font-size: 14px;
	color: var(--color-white);
	font-weight: 700;
	line-height: 1.1;
	letter-spacing: 0.5px;
	text-indent: -9999px;
	background-position: center;
	background-repeat: no-repeat;
}

.sns_login_box .sub_login {
	text-indent: 0;
}

.sns_login_box .sub_login.possible {
	background: linear-gradient(144.76deg, #18a87d 10.97%, #5377f8 85.77%);
}

/* 체크박스 공통 텍스트 체크 */
.check_tit_txt,
.check_txt {
	position: relative;
}

.check_txt input[type='checkbox'] {
	position: absolute;
	left: 0;
	width: 0px;
	height: 0px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0 none;
}

.check_txt input[type='checkbox']+label {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: 16px;
	padding: 1px 0 1px 30px;
	color: var(--color-darkgrey);
	line-height: 1.2;
}

.check_txt input[type='checkbox']+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 2px;
	width: 20px;
	height: 21px;
	background-image: url(../../public/images/icon_check.png);
	background-position: left top;
	background-repeat: no-repeat;
}

.check_txt input[type='checkbox']:focus+label {
	outline: 1px dotted #444;
	outline: 5px auto -webkit-focus-ring-color;
	outline: 0 none;
}

.check_txt input[type='checkbox']:focus+label:before {
	outline: 0 none;
}

.check_txt input[type='checkbox']:checked+label:before {
	background-position: left -20px;
}

.check_txt.check_tit {
	margin-bottom: 15px;
	font-size: 18px;
	font-weight: 700;
}

/* input */
.input_area {
	position: relative;
	width: 100%;
	margin-top: 24px;
}

.input_area:first-child {
	margin-top: 0;
}

.input_area label,
.input_area .label {
	display: inline-block;
	margin-bottom: 7px;
	font-size: 16px;
	color: #c0c0c0;
}

.input_area .essential:after {
	content: '*';
	padding: 0 0 0 5px;
	color: var(--color-video);
}

.input_area input,
.input_area .input_style,
.input_area textarea {
	display: block;
	width: 100%;
	height: 50px;
	line-height: 48px;
	padding: 0 15px;
	color: var(--color-midgrey);
	border: 1px solid var(--color-lightgrey);
	background: var(--color-white);
	border-radius: 4px;
}

.input_area .input_style {
	line-height: 44px;
}

.input_area textarea {
	height: 120px;
	padding: 13px 15px;
	line-height: 24px;
	resize: none;
}

.input_area span.color_green {
	font-size: 14px;
}

.input_area input::placeholder,
.input_area textarea::placeholder {
	color: var(--color-midgrey);
}

.input_area input:focus,
.input_area textarea:focus {
	border-color: var(--color-video);
	outline: none;
}

.input_area input:disabled,
.input_area textarea:disabled {
	background-color: var(--color-sub2);
}

.input_area button {
	display: inline-block;
	min-width: auto;
	height: 50px;
	line-height: 48px;
	padding: 0 16px;
	text-align: center;
	margin-left: 7px;
	color: var(--color-white-alpha80);
	border: 1px solid var(--color-white-alpha50);
	border-radius: 4px;
	font-weight: 400;
}

.input_area .btn {
	flex: none;
}

.input_area button.active {
	border: 1px solid var(--color-video);
	background: var(--color-video-alpha50);
	color: var(--color-white);
}

.input_area .btn.disabled:hover,
.input_area .btn.disabled {
	border: 1px solid var(--color-grey);
	background: var(--color-sub2);
	color: var(--color-midgrey);
	cursor: default;
}

.input_area.focus_on label,
.input_area.focus_on .label {
	color: var(--color-video);
}

.input_btn_area {
	display: flex;
}

/* select 스타일의 a,button */
.select_style {
	position: relative;
	font-weight: 500;
	border: 0 none;
}

.select_style .label {
	width: 100%;
	height: 49px;
	padding: 0 40px 10px 15px;
	margin: 0;
	color: var(--color-midgrey);
	border: 1px solid var(--color-grey);
	background: var(--color-white);
	border-radius: 4px;
	text-align: left;
}

.select_style .label::after {
	content: '';
	display: inline-block;
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
	height: 100%;
	line-height: 48px;
	text-align: center;
	margin: 0;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	background: url(../../public/images/icon_select_gray.png) center no-repeat;
}

.select_style.open .label {
	color: var(--color-video);
}

.select_style.open .label::after {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.select_style ul {
	display: none;
	position: absolute;
	top: 50px;
	right: 0;
	width: 100%;
	max-height: 142px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 9px 0;
	border: 1px solid var(--color-grey);
	background: var(--color-white);
	border-radius: 4px;
	z-index: 11;
	-webkit-box-shadow: none;
	/*box-shadow: 3px 4px 7px 2px rgba(156, 156, 156, 0.24);*/
}

.select_style li a,
.select_style li button {
	display: block;
	width: 100%;
	height: auto;
	line-height: inherit;
	padding: 8px 10px 9px;
	margin: 0;
	color: var(--color-midgrey);
	z-index: 1;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	border: 0 none;
	text-align: left;
	word-wrap: break-word;
}

.select_style li a:hover,
.select_style li button:hover {
	font-weight: 700;
}

/* select, input 한 묶음일 때 */
.select_input {
	display: flex;
}

.select_input .select_style {
	flex: none;
	margin-right: 7px;
}

/* MY INFO */
.half_area {
	display: flex;
	margin-left: -16px;
	margin-top: 24px;
}

.half_area:first-child {
	margin-top: 0;
}

.half_area .input_area {
	margin-left: 16px;
	margin-top: 0;
}

/* my info 연결된 SNS 계정 */
.my_info_sns {
	margin-top: 8px;
}

.my_info_sns ul {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-left: -16px;
}

.my_info_sns li {
	position: relative;
	flex-basis: calc(50% - 16px);
	width: calc(50% - 16px);
	display: flex;
	align-items: center;
	min-height: 94px;
	margin: 16px 0 0 16px;
	padding: 20px 24px;
	font-size: 20px;
	color: var(--color-white);
	border: 1px solid #696969;
	border-radius: 4px;
	background-color: var(--color-sub2);
}

.my_info_sns li i {
	display: inline-block;
	width: 46px;
	height: 46px;
	margin: 0;
	margin-right: 32px;
	background-color: #9e9e9e;
	border-radius: 50px;
	text-indent: -9999px;
	background-position: center;
	background-repeat: no-repeat;
}

.my_info_sns li i.kakao_login {
	background-size: 22px auto;
}

.my_info_sns li i.naver_login {
	background-size: 18px auto;
}

.my_info_sns li i.google_login {
	background-size: 22px auto;
}

.my_info_sns li p {
	font-weight: 700;
}

.my_info_sns li .connect_txt,
.my_info_sns li .connect_btn {
	position: absolute;
	top: 50%;
	right: 24px;
	font-weight: 300;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.my_info_sns li .connect_txt {
	color: var(--color-midgrey);
}

.my_info_sns li .connect_btn {
	padding: 12px 16px;
	color: var(--color-white);
	border: 1px solid var(--color-video);
	background-color: var(--color-video-alpha50);
	border-radius: 4px;
}

/* 구입콘텐츠 확인 */
#layerpop_buy .layerpop_area {
	width: 664px;
}

#layerpop_buy .next_btn button {
	min-width: 182px;
}

#layerpop_buy .next_btn .type2 {
	min-width: 99px;
}

#layerpop_buy .cont_info {}

#layerpop_buy .cont_info::after {
	content: '';
	clear: both;
	display: block;
}

.cont_info .img_area {
	position: relative;
	width: 274px;
	float: left;
	padding-top: 26%;
	border-radius: 4px;
	overflow: hidden;
}

.cont_info .img_area video,
.cont_info .img_area img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	vertical-align: middle;
}

.cont_info .txt_area {
	float: right;
	width: calc(100% - 298px);
	font-size: 14px;
	color: var(--color-deepgrey);
}

.cont_info .txt_area .tit {
	margin: 2px 0 19px;
	font-size: 18px;
	font-weight: 700;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
}

.cont_info .txt_area .file_time {
	padding: 3px 0 0 24px;
	height: 20px;
	color: var(--color-grey);
	background: url(../../public/images/icon_time.png) 1px center no-repeat;
}

.cont_info .txt_area dt,
.cont_info .txt_area dd {
	display: inline-block;
	float: left;
}

.cont_info .txt_area .file_size {
	margin: 6px 0 19px;
	min-width: 191px;
	display: inline-block;
	font-weight: 700;
	height: 43px;
	line-height: 27px;
	padding: 7px 15px 0;
	border: 1px solid var(--color-lightgrey2);
	border-radius: 4px;
}

.cont_info .txt_area .file_size dt {
	margin-right: 45px;
}

.cont_info .txt_area .file_size dd {
	float: right;
	height: 27px;
	font-size: 12px;
	font-weight: 400;
	padding: 0 7px;
	background: var(--color-lightpuple);
	border-radius: 4px;
}

.cont_info .txt_area .file_size dd strong {
	font-size: 16px;
	font-weight: 700;
	margin-right: 3px;
}

.cont_info .txt_area .price {
	clear: both;
	color: var(--color-sub-alpha70);
}

.cont_info .txt_area .price dt {
	margin: 8px 16px 0 0;
}

.cont_info .txt_area .price dd {
	font-size: 16px;
	font-weight: 700;
	color: var(--color-video);
}

.cont_info .txt_area .price dd strong {
	font-size: 28px;
	font-weight: 700;
	margin-right: 4px;
	letter-spacing: -1px;
}

/* 로그인 팝업 */
#layerpop_login_join_consent .input_area+.input_area,
#layerpop_login .input_area+.input_area {
	margin-top: 12px;
}

/* sns_join_area */
.sns_join_area.content {
	padding-top: 0;
}

.sns_join_area .sns_login_box {
	margin: 10px 0;
}

/* 아이디/비밀번호 찾기 */
#layerpop_find .layerpop_area {
	width: 560px;
}

#layerpop_find .cont_txt_s {
	font-size: 14px;
	margin: 14px 0;
	text-align: left;
}

.find_form .input_area+.input_area {
	margin-top: 16px;
}

.find_check {
	width: 100%;
	overflow: hidden;
	margin-bottom: 42px;
}

.find_check .radio_item {
	float: left;
	width: 50%;
}

.radio_item input[type='radio'] {
	position: absolute;
	left: 0;
	width: 0px;
	height: 0px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0 none;
}

.radio_item input[type='radio']+label {
	position: relative;
	display: inline-block;
	width: 100%;
	padding: 10px 0 15px;
	text-align: center;
	font-size: 18px;
	font-weight: 500;
	color: var(--color-grey);
	line-height: 1.2;
}

.radio_item input[type='radio']+label:before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-color: var(--color-lightgrey);
}

.radio_item input[type='radio']:focus+label {
	outline: 1px dotted #444;
	outline: 5px auto -webkit-focus-ring-color;
	outline: 0 none;
}

.radio_item input[type='radio']:focus+label:before {
	outline: 0 none;
}

.radio_item input[type='radio']:checked+label:before {
	height: 2px;
	background-color: var(--color-video);
}

/* input */
.input_btn_area {
	position: relative;
}

.input_area .time {
	position: absolute;
	top: 0;
	right: 158px;
	line-height: 50px;
	font-weight: 500;
	color: var(--color-midgrey);
}

.find_input .btn {
	width: 135px;
	color: var(--color-white);
	border-radius: 4px;
	border: 1px solid var(--color-video);
	background-color: var(--color-video);
}

.find_input.num_input .btn {
	border-color: var(--color-darkgrey);
	background-color: var(--color-darkgrey);
}

/* 이용약관 tab */
.terms_tab {
	margin-top: 40px;
	font-size: 0;
}

.terms_tab li {
	display: inline-block;
}

.terms_tab button {
	display: inline-block;
	width: auto;
	padding: 20px 0 10px;
	margin-right: 35px;
	font-weight: 700;
	font-size: 16px;
}

.terms_tab li.active button {
	border-bottom: 2px solid var(--color-video);
}

.terms_tab_area {
	margin-top: 40px;
}

.terms_tab_area .terms_tab_cont {
	display: none;
}

.terms_tab_area .terms_tab_cont.active {
	display: block;
}

/* 이용약관 */
.sub_cont * {
	/*letter-spacing: -0.75px;*/
	color: var(--color-lightgrey);
}

.sub_cont h4 {
	/*font-size:15px;*/
	font-weight: 700;
	margin-bottom: 20px;
	line-height: 1.4;
	color: var(--color-white);
}

.sub_cont h5 {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 15px;
	margin-top: 30px;
}

.sub_cont p {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 25px;
	line-height: 1.4;
}

.sub_cont p+p {
	margin-top: -15px;
}

.sub_cont p+ul {
	margin-top: -10px;
}

.sub_cont a {
	color: var(--color-video);
}

.sub_cont a:hover {
	text-decoration: underline;
}

.list_num.list_depht2 {
	margin-left: 20px;
}

.list_num {
	margin-bottom: 15px;
}

.list_num li {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
	line-height: 1.4;
	color: var(--color-lightgrey);
	margin: 10px 0;
	padding-left: 21px;
	position: relative;
}

.list_num li span {
	position: absolute;
	top: 1px;
	left: 0;
	font-size: 10px;
	top: 2px;
}

.list_num li span.num10 {
	font-size: 15px;
	top: -2px;
	font-weight: 700;
}

.list_num.list_depht2 li span,
.list_num li li span {
	top: 0;
	font-size: 14px;
	/* text-align:right;width:15px;display:inline-block;*/
}

.list_dash li {
	font-size: 13px;
	font-weight: 400;
	margin-bottom: 20px;
	line-height: 1.4;
	color: var(--color-lightgrey);
	margin: 10px 0;
	padding-left: 15px;
	position: relative;
}

.list_dash li::before {
	content: '';
	width: 5px;
	height: 1.2px;
	display: block;
	position: absolute;
	top: 8px;
	left: 0;
	background: var(--color-lightgrey);
}

/* table */
.tableA {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 20px;
	line-height: 1.4;
	width: 100%;
	margin-top: 10px;
}

.tableA caption {
	overflow: hidden;
	position: relative;
	width: 1px;
	height: 1px;
	margin: 0;
	padding: 0;
	line-height: 0;
	font-size: 0;
}

.tableA th,
.tableA td {
	padding: 10px 12px;
	vertical-align: middle;
	border: 1px solid var(--color-lightgrey);
}

.tableA th {
	background-color: var(--color-white-alpha20);
}

.tdCenter th,
.tdCenter td {
	text-align: center;
}

/* 맨 상단이 video_menu 메뉴일 때 color */
.top_nav .video:hover::before,
.video_menu .top_nav .active::before,
.video_menu #expand_menu .expand_2depth li a:hover:before,
.video_menu .details_btns .btn_buy.active {
	background-color: var(--color-video);
}

.video_menu .details_btns .btn_buy.active {
	border-color: var(--color-video);
}

.video_menu .details_info .keyword_list button {
	border-color: var(--color-video-alpha50);
}

.video_menu .details_info .keyword_list button.active,
.video_menu .details_info .keyword_list button:hover {
	background-color: var(--color-video-alpha50);
}

/* themes_1depth */
.video_menu .themes_menu .themes_1depth button.active {
	color: var(--color-video);
}

.video_menu .themes_menu .themes_2depth_list button.active {
	color: var(--color-video);
}

.video_menu .themes_menu .themes_2depth_list button.active::before {
	background: url(../../public/images/icon_themes_arrow.png) center no-repeat;
}

.video_menu .search_history .list {
	border: 1px solid var(--color-video);
	background: transparent;
}

/* 관심 active */
.video_menu .btn.btn_like.active span::before,
.video_menu .icon_heart.active {
	background-image: url(../../public/images/icon_heart_video.png);
}

/* 맨 상단이 audio_menu 메뉴일 때 color */
.top_nav .audio:hover::before,
.audio_menu .top_nav .active::before,
.audio_menu #expand_menu .expand_2depth li a:hover:before,
.audio_menu .details_btns .btn_buy.active,
.top_nav .sound:hover::before,
.sound_menu .top_nav .active::before,
.sound_menu #expand_menu .expand_2depth li a:hover:before,
.sound_menu .details_btns .btn_buy.active:hover {
	background-color: var(--color-sound);
}

.audio_menu .details_btns .btn_buy.active,
.sound_menu .details_btns .btn_buy.active {
	border-color: var(--color-sound);
}

.audio_menu .details_info .keyword_list button,
.sound_menu .details_info .keyword_list button {
	border-color: var(--color-sound-alpha50);
}

.audio_menu .details_info .keyword_list button.active,
.audio_menu .details_info .keyword_list button:hover,
.sound_menu .details_info .keyword_list button.active,
.sound_menu .details_info .keyword_list button:hover {
	background-color: var(--color-sound-alpha50);
}

/* themes_1depth */
.audio_menu .themes_menu .themes_1depth button.active {
	color: var(--color-sound);
}

.audio_menu .themes_menu .themes_2depth_list button.active {
	color: var(--color-sound);
}

.audio_menu .themes_menu .themes_2depth_list button.active::before {
	background: url(../../public/images/icon_themes_audio_arrow.png) center no-repeat;
}

.audio_menu .search_history .list {
	border: 1px solid var(--color-sound);
	background: transparent;
}

/* 관심 active */
.audio_menu .btn.btn_like.active span::before,
.sound_menu .btn.btn_like.active span::before,
.audio_menu .icon_heart.active,
.sound_menu .icon_heart.active {
	background-image: url(../../public/images/icon_heart_sound.png);
}

/* 구입콘텐츠 확인 layer pop */
.audio_menu .cont_info .txt_area .price dd,
.sound_menu .cont_info .txt_area .price dd {
	color: var(--color-sound);
}

.audio_menu .layerpop .next_btn button,
.sound_menu .layerpop .next_btn button {
	background-color: var(--color-sound);
}

.icon_heart.active {
	background-image: url(../../public/images/icon_heart_video.png);
}

/* p_title */
.p_title {
	padding-bottom: 47px;
	border-bottom: 1px solid var(--color-white-alpha10);
}

.p_title .location {
	font-size: 14px;
	color: var(--color-midgrey);
}

.p_title h2 {
	margin-top: 20px;
	line-height: 24px;
	color: var(--color-white);
	font-size: 40px;
	font-weight: 700;
}

.p_title .desc {
	margin-top: 16px;
	color: var(--color-lightgrey);
}

/* no_data */
#container .no_data {
	width: 100%;
	text-align: center;
	padding: 120px 0;
	color: #fff;
}

/* 콘텐츠 상세 */
.view_content {
	/* margin-top:46px; */
	padding: 48px 40px 62px;
	color: var(--color-white);
}

.view_content>.p_title {
	margin-top: 20px;
}

.btn {
	float: left;
	display: inline-block;
	min-width: 102px;
	height: 48px;
	line-height: 46px;
	text-align: center;
	font-weight: 700;
	color: var(--color-white-alpha80);
	border: 1px solid var(--color-white-alpha50);
	border-radius: 4px;
}

.btn span {
	position: relative;
	padding-left: 34px;
}

.btn span::before {
	content: '';
	position: absolute;
	left: 0;
	top: -2px;
	width: 24px;
	height: 24px;
}

.btn+.btn {
	margin-left: 8px;
}

.btn.no_icon span {
	padding-left: 0;
}

.viewContent_wrap {
	margin-top: 24px;
}

.cont_list {
	clear: both;
	display: flex;
	flex-wrap: wrap;
	gap: 8px 8px;
}

.cont_list.audio_list {
	margin-bottom: 28px;
}

.cont_list .list {
	position: relative;
	float: left;
	flex-basis: calc(25% - 6px);
}

.cont_list .list a {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
}

.cont_list .list .img,
.cont_list .list .video_box {
	position: relative;
	width: 100%;
	padding-top: 54.628%;
	overflow: hidden;
	z-index: 1;
}

.cont_list .list .Img video,
.cont_list .list .img img,
.cont_list .list .video_box video,
.cont_list .list .video_box img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	vertical-align: middle;
	z-index: 1;
}

.cont_list .list .tit,
.cont_list .list .by {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 0 76px 0 16px;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
	z-index: 3;
}

.cont_list .list .tit {
	bottom: 34px;
	color: var(--color-white);
	font-weight: 700;
}

.cont_list .list .by {
	bottom: 13px;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.6);
	line-height: 1.1;
}

.cont_list .list a .txt {
	position: absolute;
	left: 0;
	width: 100%;
	font-size: 14px;
	padding-top: 14px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
}

.cont_list .list .list_btn {
	position: absolute;
	bottom: 23px;
	right: 16px;
	width: 20px;
	border-radius: 4px;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	z-index: 4;
}

.cont_list .list .list_btn .icon_share {
	margin: 12px 0;
}

.cont_list .list:hover .video_box {
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
}

.cont_list .list:hover .video_box::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 29.55%, rgba(0, 0, 0, 0) 100%);
	z-index: 2;
}

.cont_list .list:hover .video_box::after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border: 2px solid var(--color-main);
	border-radius: 4px;
	z-index: 2;
}

.cont_list .list:hover .tit,
.cont_list .list:hover .by,
.cont_list .list:hover .list_btn {
	opacity: 1;
	visibility: visible;
}

.details_top {
	margin-bottom: 40px;
}

.details_top::after {
	content: '';
	clear: both;
	display: block;
}

.details_preview {
	position: relative;
	float: left;
	width: calc(65% - 62px);
}

.details_preview .btn_help {
	display: none;
	position: absolute;
	right: 16px;
	top: 16px;
	z-index: 2;
}

.details_preview .btn_help button {
	width: 32px;
	height: 32px;
	background: rgba(0, 0, 0, 0.3) url(../../public/images/icon_help.png) center no-repeat;
	border-radius: 100%;
}

.details_preview .thumb {
	position: relative;
	width: 100%;
	padding-top: 56.223%;
	border-radius: 4px;
	overflow: hidden;
}

.details_preview .thumb img,
.details_preview .thumb video,
.details_preview .thumb iframe {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	vertical-align: middle;
	border: 0 none;
}

.details_preview .btns {
	margin-top: 16px;
}

.details_preview .btns::after {
	content: '';
	clear: both;
	display: block;
}

.btn.btn_like span::before {
	background: url(../../public/images/icon_heart.png) center no-repeat;
}

.btn.btn_share span::before {
	background: url(../../public/images/icon_share.png) center no-repeat;
}

.btn.btn_download span::before {
	background: url(../../public/images/icon_download.png) center no-repeat;
}

.details_info {
	float: right;
	width: calc(35% - 32px);
	margin-right: 32px;
}

.details_info .p_title {
	padding-bottom: 15px;
	border-bottom: 1px solid #3d3d3d;
}

.details_info .p_title h2 {
	margin-top: 9px;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
}

.details_info strong {
	font-size: 14px;
	font-weight: 700;
	color: var(--color-midgrey);
}

.details_info .details_etc {
	margin-top: 21px;
}

.details_info .details_etc li {
	margin-top: 5px;
}

.details_info .details_etc strong {
	display: inline-block;
	width: 75px;
	padding-right: 14px;
	color: var(--color-grey);
	font-weight: 400;
	text-align: right;
}

.details_info .details_etc span {
	font-size: 14px;
	color: var(--color-white-alpha80);
}

.details_info .details_etc .creator {
	padding-bottom: 8px;
}

.details_info .details_etc .creator strong {
	font-weight: 700;
}

.details_info .details_etc .creator span {
	color: var(--color-white);
	font-weight: 700;
}

.details_info .price strong,
.details_info .keyword_list strong {
	display: block;
}

.details_info .price {
	margin-top: 17px;
}

.details_info .price strong {
	margin-bottom: 13px;
}

.details_info .price em {
	font-size: 24px;
	font-weight: 700;
	margin-right: 6px;
}

.details_info .keyword_list {
	margin-top: 60px;
}

.details_info .keyword_list button {
	float: left;
	width: auto;
	height: 37px;
	line-height: 35px;
	margin: 10px 16px 0 0;
	padding: 0 16px;
	font-size: 14px;
	color: var(--color-white);
	border: 1px solid var(--color-video-alpha50);
	border-radius: 100px;
}

.details_info .keyword_list button.active,
.details_info .keyword_list button:hover {
	background-color: var(--color-video-alpha50);
}

.details_btns {
	border-top: 1px solid #3d3d3d;
	padding-top: 20px;
	margin-top: 24px;
}

.details_btns::after {
	content: '';
	clear: both;
	display: block;
}

.details_btns .btn {
	width: calc(50% - 4px);
	margin-top: 17px;
}

.details_btns .btn_buy {
	width: 100%;
	height: 56px;
	line-height: 56px;
	margin-top: 0;
	text-align: center;
	font-weight: 700;
	font-size: 18px;
	color: var(--color-white-alpha80);
	border: 1px solid var(--color-white-alpha50);
	border-radius: 4px;
	overflow: hidden;
}

.details_btns .btn_buy.active {
	color: var(--color-white);
}

.details_btns .btn_buy span {
	position: relative;
	padding-left: 34px;
}

.details_btns .btn_buy span::before {
	content: '';
	position: absolute;
	left: 0;
	top: 2px;
	width: 20px;
	height: 20px;
	background: url(../../public/images/icon_cart.png) center no-repeat;
}

.details_btns .btn[disabled],
.details_btns .btn[disabled='disabled'],
.details_btns .btn_buy[disabled],
.details_btns .btn_buy[disabled='disabled'] {
	background-color: var(--color-white-alpha20) !important;
	border: 0 none;
	color: var(--color-white-alpha50);
	cursor: inherit;
}

.details_btns .btn[disabled] span,
.details_btns .btn[disabled='disabled'] span,
.details_btns .btn_buy[disabled] span,
.details_btns .btn_buy[disabled='disabled'] span {
	padding-left: 0;
}

.details_btns .btn[disabled] span::before,
.details_btns .btn[disabled='disabled'] span::before,
.details_btns .btn_buy[disabled] span::before,
.details_btns .btn_buy[disabled='disabled'] span::before {
	display: none;
	/*opacity:.5;*/
}

.details_info .guide_txt {
	clear: both;
	font-size: 14px;
	color: var(--color-midgrey);
	margin-top: 17px;
}

.details_info .guide_txt::before {
	content: 'ㆍ';
	font-weight: 700;
	color: var(--color-video);
}

.details_info .guide_txt+.guide_txt {
	margin-top: 10px;
}

.details_con {
	font-size: 20px;
	margin-top: 33px;
	color: #d3d3d3;
	/*line-height:32px;*/
	line-height: 1;
}

.recommend_contents {
	margin-top: 57px;
}

.recommend_contents::after {
	content: '';
	clear: both;
	display: block;
}

.recommend_contents h3 {
	font-size: 24px;
	color: var(--color-white);
	margin-bottom: 15px;
}

/* 게시판 상세 */
.board_content {
	position: relative;
	width: 1200px;
	/* margin:46px auto 0; */
	margin: 0 auto;
	padding: 114px 0px;
	color: var(--color-white);
}

#wrap::after {
	content: '';
	clear: both;
	display: block;
}

.board_content .p_title {
	padding-bottom: 31px;
	border-bottom: 2px solid var(--color-line-darkgrey);
}

.board_content .p_title h2 {
	line-height: 48px;
	font-weight: 400;
	margin-top: 15px;
}

.board_content .board_wrap {
	min-height: 460px;
}

.board_wrap h3 {
	position: relative;
	margin-top: 40px;
	font-size: 20px;
	line-height: 24px;
	padding-left: 24px;
	color: var(--color-white);
}

.board_wrap h3::before {
	content: '';
	position: absolute;
	left: 0;
	top: 6px;
	display: inline-block;
	width: 12px;
	height: 12px;
	background-color: var(--color-video);
	border-radius: 100%;
}

.board_wrap h3:first-child {
	margin-top: 90px;
}

.board_btns {
	margin-top: 40px;
	width: 100%;
	text-align: center;
}

.board_btns .btn {
	float: none;
	min-width: 222px;
	padding: 18px;
	height: auto;
	line-height: inherit;
	font-size: 18px;
	font-weight: 500;
}

.board_btns.btn_right {
	text-align: right;
	margin-top: -46px;
}

.board_wrap .board_btns.btn_right .btn {
	min-width: 182px;
}

/* board_list */
.board_list {}

.board_list li {
	border-bottom: 1px solid var(--color-line-darkgrey);
	font-weight: 400;
	position: relative;
}

.board_list li span {
	display: inline-block;
	padding: 20px 0 17px;
	vertical-align: middle;
	line-height: 1.3;
}

.board_list li span.tit {
	width: calc(100% - 214px);
	padding-right: 72px;
}

.board_list li span:first-child {
	color: var(--color-video);
	font-weight: 700;
	min-width: 86px;
	margin-right: 40px;
}

.board_list li span.date {
	color: var(--color-midgrey);
	font-size: 14px;
	position: absolute;
	right: 0;
	top: 0;
	width: 88px;
	height: 100%;
	padding: 2px 0 3px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.board_list li span>a {
	text-decoration: none;
	color: inherit;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.board_list li:hover span>a {
	text-decoration: underline;
}

/* paging */
.paging {
	margin-top: 30px;
	padding: 0 20px;
	text-align: center;
}

.paging button {
	display: inline-block;
	height: 34px;
	line-height: 34px;
	margin: 0 6px;
	padding: 0 10px;
	color: var(--color-midgrey);
	vertical-align: top;
}

.paging button.active,
.paging button:hover,
.paging button:focus {
	font-weight: 700;
}

.paging button.active {
	color: var(--color-white);
}

.paging .p_btn {
	position: relative;
	width: 34px;
	padding: 0;
	margin: 0;
	text-indent: -9999px;
}

.paging .p_btn::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #d5d5d5;
}

.paging .prev::before,
.paging .next::before {
	-webkit-mask: url(../../public/images/icon_next.svg) center no-repeat;
	mask: url(../../public/images/icon_next.svg) center no-repeat;
}

.paging .first::before,
.paging .last::before {
	-webkit-mask: url(../../public/images/icon_last.svg) center no-repeat;
	mask: url(../../public/images/icon_last.svg) center no-repeat;
}

.paging .prev::before,
.paging .first::before {
	transform: rotate(-180deg);
}

.paging .disabled {
	cursor: inherit;
}

.paging .disabled::before {
	background-color: #909090;
}

/* board_view */
.board_view {
	padding-bottom: 32px;
	border-bottom: 1px solid var(--color-line-darkgrey);
}

.board_view p {
	word-break: keep-all;
}

.board_view .board_list {
	padding-top: 0;
}

.board_view .board_con {
	padding: 32px 0 20px;
	word-break: break-all;
	color: #d3d3d3;
	line-height: 32px;
}

.board_view .board_con_bottom {}

.board_view .file_up {
	position: relative;
	margin: 0;
}

.board_view .file_up .btn_file {
	display: inline-block;
	min-height: 37px;
	margin: 10px 16px 0 0;
	padding: 10px 16px;
	color: var(--color-white);
	border: 1px solid var(--color-video-alpha50);
	border-radius: 37px;
}

.board_view .file_up .btn_file:hover {
	background-color: var(--color-video-alpha50);
}

.board_view .file_up .btn_file em {
	position: relative;
	padding-left: 24px;
	display: block;
	font-size: 14px;
	font-weight: 400;
}

.board_view .file_up .btn_file em:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: -1px;
	width: 16px;
	height: 17px;
	background: url(../../public/images/icon_file.png) center no-repeat;
}

/* board_write */
.board_write {
	margin-top: 24px;
	padding: 40px;
	border-radius: 4px;
	background-color: var(--color-deepgrey2);
	border: 1px solid var(--color-darkgrey);
}

.board_write .file_box {
	position: relative;
}

.board_write .file_txt {
	clear: both;
	font-size: 16px;
	color: #c0c0c0;
	margin-top: 16px;
}

.board_write .file_txt p+p {
	margin-top: 8px;
}

.board_write .btn_file {
	position: absolute;
	left: 0;
	top: 0;
	padding: 12px 16px;
	margin-bottom: 0;
	color: var(--color-white);
	cursor: pointer;
	border: 1px solid var(--color-video);
	background-color: var(--color-video-alpha50);
	border-radius: 4px;
	z-index: 2;
}

.board_write .btn_upload {
	position: absolute;
	right: 0;
	top: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	border: 0 none;
}

.board_write .file_up {
	position: relative;
	font-size: 15px;
	padding-left: 120px;
	z-index: 1;
	min-height: 42px;
}

.board_write .file_up .file_info {
	display: inline-block;
	width: auto;
	padding: 4px 0;
	cursor: auto;
	margin-right: 20px;
}

.board_write .file_up .file_info>* {
	vertical-align: middle;
}

.board_write .file_up .file_del {
	display: inline-block;
	width: auto;
	height: 34px;
	line-height: 32px;
	border-radius: 34px;
	padding: 0 16px;
	color: var(--color-white);
	border: 1px solid var(--color-video-alpha50);
}

.board_write .file_up .file_del:hover {
	background-color: var(--color-video-alpha50);
}

.board_write .file_up .file_del span {
	position: relative;
	padding-right: 15px;
}

.board_write .file_up .file_del span::after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 9px;
	height: 10px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background: url(../../public/images/icon_del.png) center no-repeat;
}

.board_write .guide_txt {
	position: relative;
	line-height: 24px;
	padding-left: 14px;
}

.board_write .guide_txt::before {
	content: '*';
	position: absolute;
	left: 0;
	top: 0;
}

/* record_list */
.record_list .count_txt {
	font-size: 14px;
	line-height: 24px;
	color: var(--color-white);
	margin: 8px 0 6px;
}

.record_list .count_txt strong {
	font-weight: 700;
	margin-left: 3px;
}

.record_list .count_txt .lastPage {
	position: relative;
	color: var(--color-midgrey);
	margin-left: 4px;
	padding-left: 8px;
}

.record_list .count_txt .lastPage::before {
	content: '/';
	position: absolute;
	left: 0;
	top: 50%;
	color: var(--color-grey);
	transform: translateY(-50%) translateY(-0.1px) translateZ(0);
}

.record_list table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 2px;
}

.record_list table th,
.record_list table td {
	height: 56px;
	text-align: center;
	font-size: 14px;
	padding: 15px;
	font-weight: 400;
	color: var(--color-white);
	line-height: 1.3;
	background: var(--color-sub2);
}

.record_list table th {
	background: #3d4153;
}

.record_list table td.tit {
	text-align: left;
	padding-left: 24px;
}

.record_list table td.price {
	text-align: right;
	padding-right: 32px;
}

#audio {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0 none;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	z-index: 1;
}

/* .audio_btn {position:absolute;left:0;bottom:0;width:100%;height:106px;text-align:center;background:linear-gradient(180deg, rgba(83, 119, 248, 0) 0%, #5377F8 100%);} */
.audio_btn {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 128px;
	height: 128px;
	text-align: center;
	margin: -64px 0 0 -64px;
}

.audio_btn button {
	/*width:56px;height:56px;margin-top:10px;*/
	width: 100%;
	height: 100%;
	border-radius: 100%;
	background-color: var(--color-sub-alpha40);
	background-position: center;
	background-repeat: no-repeat;
}

.audio_btn button span {
	font-size: 0;
}

.audio_btn .btn_play {
	background-image: url(../../public/images/icon_play.png);
}

.audio_btn .btn_pause {
	display: none;
	background-image: url(../../public/images/icon_pause.png);
}

#__next {
	width: 100%;
	height: 100%;
}