@charset "utf-8";

@font-face {
	font-family: 'Pretendard Variable';
	font-weight: 45 920;
	font-style: normal;
	font-display: swap;
	src: local('Pretendard Variable'), url('Pretendard/PretendardVariable.woff2') format('woff2-variations');
}

@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-Thin.woff') format('woff');
	font-weight: 100;
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-Light.woff') format('woff');
	font-weight: 300;
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-Medium.woff') format('woff');
	font-weight: 500;
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-SemiBold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-display: swap;
}
@font-face {
	font-family: 'Pretendard';
	src: url('Pretendard/Pretendard-Black.woff') format('woff');
	font-weight: 900;
	font-display: swap;
}
