@charset "utf-8";
@import url('./fonts/Pretendard.css');

/* css Reset */
html {
	/*-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%*/
	-webkit-text-size-adjust: none;
}

header,
footer,
main,
section,
article,
nav,
aside {
	display: block;
	box-sizing: border-box;
}

*::before,
*::after {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

body,
input,
textarea,
button,
select,
table,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Pretendard, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	color: #000;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
form,
fieldset,
legend,
button,
input,
select,
optgroup,
textarea,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
strong {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

mark {
	background-color: #ff0;
	color: #000;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

figure {
	margin: 1em 40px;
}

pre {
	font-family: monospace, monospace;
	font-size: 1em;
}

template {
	display: none;
}

[hidden] {
	display: none;
}

progress {
	display: inline-block;
	vertical-align: baseline;
}

summary {
	display: list-item;
}

ol,
ul {
	list-style: none;
}

dt,
dd {
	display: block;
}

caption,
legend {
	overflow: hidden;
	font-size: 0;
	line-height: 0;
}

canvas {
	display: inline-block;
}

img {
	max-width: 100%;
	border-style: none;
	vertical-align: top;
}

fieldset,
img {
	border: none;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

input,
button,
select {
	vertical-align: top;
}

input,
button,
select,
textarea {
	font-size: inherit;
	box-sizing: border-box;
}

/*input[disabled],input[readonly]{cursor:not-allowed;background-color:#eaeaea;}*/
button,
[type='button'],
[type='reset'],
[type='submit'] {
	border: 0 none;
	background: none;
	cursor: pointer;
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: 1px dotted ButtonText;
}

/*[type="checkbox"],[type="radio"]{}*/
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}

select::-ms-expand {
	display: none;
}

textarea {
	display: block;
	overflow: auto;
	resize: vertical;
}

address,
em,
i {
	font-style: normal;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	margin: 0;
	padding: 0;
	border: 0;
}

/* table Reset */
table {
	margin: 0;
	border-collapse: collapse;
	border-spacing: 0;
}

/* hidden text */
.blind,
.hidden,
.txtComment,
#skip_con {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0 none;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
}

/* 바로가기 */
#accessibility a {
	z-index: 100000;
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
	font-size: 0;
	overflow: hidden;
}

#accessibility a:focus,
#accessibility a:active {
	width: 100%;
	height: 45px;
	padding: 10px 0;
	background: #21272e;
	color: #fff;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	line-height: 1.25em;
}

/* 아이폰 손쉬운사용 대응 바로가기 (font-size와 line-height가 없으면 포커스가 가지 않는다.) */
#accessibility.is_mobile {
	position: absolute;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
}

#accessibility.is_mobile a {
	line-height: 1.25em;
	font-size: 1.25em;
}

#accessibility.is_mobile a:focus,
#accessibility.is_mobile a:active {
	line-height: 1.25em;
}

/* placeholder 포커스시 감추기 */
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
	color: transparent;
}

/* WebKit browsers */
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
	color: transparent;
}

/* Mozilla Firefox 4 to 18 */
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
	color: transparent;
}

/* Mozilla Firefox 19+ */
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
	color: transparent;
}

/* Internet Explorer 10+ */

/* Global space */
.AlignLeft {
	text-align: left !important;
}

.AlignCenter {
	text-align: center !important;
}

.AlignRight {
	text-align: right !important;
}

.ValignTop {
	vertical-align: top !important;
}

.ValignMiddle {
	vertical-align: middle !important;
}

.ValignBottom {
	vertical-align: bottom !important;
}

.DisplayBlock {
	display: block;
}

.DisplayInBlock {
	display: inline-block;
}

.DisplayNone {
	display: none;
}

.FloatLeft {
	float: left;
}

.FloatRight {
	float: right;
}

.ClearBoth {
	clear: both;
}

.ClearContent:after {
	display: block;
	clear: both;
	content: '';
}

.BackgroundNone {
	background: none !important;
}

.BorderNone {
	border: none !important;
}

.BorderLeftNone {
	border-left: none !important;
}

.BorderRightNone {
	border-right: none !important;
}

.BorderBottomNone {
	border-bottom: none !important;
}

.PaddingNone {
	padding: 0 !important;
}

.MarginNone {
	margin: 0 !important;
}

.FontWeightNormal {
	font-weight: normal !important;
}

.FontWeight500 {
	font-weight: 500 !important;
}

.FontWeightBold {
	font-weight: bold !important;
}

.FontWeight900 {
	font-weight: 900 !important;
}

.nowrap {
	white-space: nowrap;
}

.hidden {
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
	font-size: 0;
	line-height: 0;
	margin-left: -1px;
}

.bdrRNone {
	border-right: 0 none !important;
}

.nowrap {
	white-space: nowrap;
}

.wordBreak {
	word-break: keep-all;
	word-wrap: break-word;
}

.MAL0 {
	margin-left: 0px !important;
}

.MAL5 {
	margin-left: 5px !important;
}

.MAL7 {
	margin-left: 7px !important;
}

.MAL6 {
	margin-left: 6px !important;
}

.MAL10 {
	margin-left: 10px !important;
}

.MAL11 {
	margin-left: 11px !important;
}

.MAL12 {
	margin-left: 12px !important;
}

.MAL13 {
	margin-left: 13px !important;
}

.MAL14 {
	margin-left: 14px !important;
}

.MAL15 {
	margin-left: 15px !important;
}

.MAL20 {
	margin-left: 20px !important;
}

.MAL25 {
	margin-left: 25px !important;
}

.MAL30 {
	margin-left: 30px !important;
}

.MAL31 {
	margin-left: 31px !important;
}

.MAL32 {
	margin-left: 32px !important;
}

.MAL33 {
	margin-left: 33px !important;
}

.MAL34 {
	margin-left: 34px !important;
}

.MAL35 {
	margin-left: 35px !important;
}

.MAL40 {
	margin-left: 40px !important;
}

.MAL45 {
	margin-left: 45px !important;
}

.MAL50 {
	margin-left: 50px !important;
}

.MAL60 {
	margin-left: 60px !important;
}

.MAL70 {
	margin-left: 70px !important;
}

.MAR0 {
	margin-right: 0px !important;
}

.MAR5 {
	margin-right: 5px !important;
}

.MAR10 {
	margin-right: 10px !important;
}

.MAR15 {
	margin-right: 15px !important;
}

.MAR17 {
	margin-right: 17px !important;
}

.MAR20 {
	margin-right: 20px !important;
}

.MAR25 {
	margin-right: 25px !important;
}

.MAR30 {
	margin-right: 30px !important;
}

.MAR35 {
	margin-right: 35px !important;
}

.MAR40 {
	margin-right: 40px !important;
}

.MAB0 {
	margin-bottom: 0px !important;
}

.MAB5 {
	margin-bottom: 5px !important;
}

.MAB10 {
	margin-bottom: 10px !important;
}

.MAB15 {
	margin-bottom: 15px !important;
}

.MAB20 {
	margin-bottom: 20px !important;
}

.MAB25 {
	margin-bottom: 25px !important;
}

.MAB30 {
	margin-bottom: 30px !important;
}

.MAB35 {
	margin-bottom: 35px !important;
}

.MAB40 {
	margin-bottom: 40px !important;
}

.MAB45 {
	margin-bottom: 45px !important;
}

.MAB50 {
	margin-bottom: 50px !important;
}

.MAB55 {
	margin-bottom: 55px !important;
}

.MAB60 {
	margin-bottom: 60px !important;
}

.MAT_2 {
	margin-top: -2px !important;
}

.MAT_1 {
	margin-top: -1px !important;
}

.MAT0 {
	margin-top: 0 !important;
}

.MAT1 {
	margin-top: 1px !important;
}

.MAT2 {
	margin-top: 2px !important;
}

.MAT5 {
	margin-top: 5px !important;
}

.MAT6 {
	margin-top: 6px !important;
}

.MAT7 {
	margin-top: 7px !important;
}

.MAT8 {
	margin-top: 8px !important;
}

.MAT9 {
	margin-top: 9px !important;
}

.MAT10 {
	margin-top: 10px !important;
}

.MAT11 {
	margin-top: 11px !important;
}

.MAT12 {
	margin-top: 12px !important;
}

.MAT13 {
	margin-top: 13px !important;
}

.MAT14 {
	margin-top: 14px !important;
}

.MAT15 {
	margin-top: 15px !important;
}

.MAT16 {
	margin-top: 16px !important;
}

.MAT20 {
	margin-top: 20px !important;
}

.MAT21 {
	margin-top: 21px !important;
}

.MAT22 {
	margin-top: 22px !important;
}

.MAT23 {
	margin-top: 23px !important;
}

.MAT24 {
	margin-top: 24px !important;
}

.MAT25 {
	margin-top: 25px !important;
}

.MAT26 {
	margin-top: 26px !important;
}

.MAT27 {
	margin-top: 27px !important;
}

.MAT28 {
	margin-top: 28px !important;
}

.MAT29 {
	margin-top: 29px !important;
}

.MAT30 {
	margin-top: 30px !important;
}

.MAT31 {
	margin-top: 31px !important;
}

.MAT32 {
	margin-top: 32px !important;
}

.MAT33 {
	margin-top: 33px !important;
}

.MAT34 {
	margin-top: 34px !important;
}

.MAT35 {
	margin-top: 35px !important;
}

.MAT36 {
	margin-top: 36px !important;
}

.MAT37 {
	margin-top: 37px !important;
}

.MAT38 {
	margin-top: 38px !important;
}

.MAT39 {
	margin-top: 39px !important;
}

.MAT40 {
	margin-top: 40px !important;
}

.MAT41 {
	margin-top: 41px !important;
}

.MAT42 {
	margin-top: 42px !important;
}

.MAT43 {
	margin-top: 43px !important;
}

.MAT44 {
	margin-top: 44px !important;
}

.MAT45 {
	margin-top: 45px !important;
}

.MAT46 {
	margin-top: 46px !important;
}

.MAT47 {
	margin-top: 47px !important;
}

.MAT48 {
	margin-top: 48px !important;
}

.MAT49 {
	margin-top: 49px !important;
}

.MAT50 {
	margin-top: 50px !important;
}

.MAT55 {
	margin-top: 55px !important;
}

.MAT56 {
	margin-top: 56px !important;
}

.MAT57 {
	margin-top: 57px !important;
}

.MAT58 {
	margin-top: 58px !important;
}

.MAT59 {
	margin-top: 59px !important;
}

.MAT60 {
	margin-top: 60px !important;
}

.MAT70 {
	margin-top: 70px !important;
}

.MAT80 {
	margin-top: 80px !important;
}

.MAT100 {
	margin-top: 100px !important;
}

.MAT120 {
	margin-top: 120px !important;
}

.MAT-5 {
	margin-top: -5px !important;
}

.MAT-10 {
	margin-top: -10px !important;
}

.MAT-15 {
	margin-top: -15px !important;
}

.MAT-20 {
	margin-top: -20px !important;
}

.MAT-25 {
	margin-top: -25px !important;
}

.MAT-30 {
	margin-top: -30px !important;
}

.MAT-35 {
	margin-top: -35px !important;
}

.MAT-40 {
	margin-top: -40px !important;
}

.PAL0 {
	padding-left: 0px !important;
}

.PAL5 {
	padding-left: 5px !important;
}

.PAL10 {
	padding-left: 10px !important;
}

.PAL15 {
	padding-left: 15px !important;
}

.PAL20 {
	padding-left: 20px !important;
}

.PAL25 {
	padding-left: 25px !important;
}

.PAL30 {
	padding-left: 30px !important;
}

.PAL35 {
	padding-left: 35px !important;
}

.PAL40 {
	padding-left: 40px !important;
}

.PAL45 {
	padding-left: 45px !important;
}

.PAL50 {
	padding-left: 50px !important;
}

.PAR0 {
	padding-right: 0px !important;
}

.PAR5 {
	padding-right: 5px !important;
}

.PAR10 {
	padding-right: 10px !important;
}

.PAR15 {
	padding-right: 15px !important;
}

.PAR20 {
	padding-right: 20px !important;
}

.PAR25 {
	padding-right: 25px !important;
}

.PAR30 {
	padding-right: 30px !important;
}

.PAR35 {
	padding-right: 35px !important;
}

.PAR40 {
	padding-right: 40px !important;
}

.PAT0 {
	padding-top: 0px !important;
}

.PAT5 {
	padding-top: 5px !important;
}

.PAT10 {
	padding-top: 10px !important;
}

.PAT15 {
	padding-top: 15px !important;
}

.PAT20 {
	padding-top: 20px !important;
}

.PAT25 {
	padding-top: 25px !important;
}

.PAT30 {
	padding-top: 30px !important;
}

.PAT35 {
	padding-top: 35px !important;
}

.PAT40 {
	padding-top: 40px !important;
}

.PAB0 {
	padding-bottom: 0px !important;
}

.PAB5 {
	padding-bottom: 5px !important;
}

.PAB10 {
	padding-bottom: 10px !important;
}

.PAB15 {
	padding-bottom: 15px !important;
}

.PAB20 {
	padding-bottom: 20px !important;
}

.PAB25 {
	padding-bottom: 25px !important;
}

.PAB30 {
	padding-bottom: 30px !important;
}

.PAB35 {
	padding-bottom: 35px !important;
}

.PAB40 {
	padding-bottom: 40px !important;
}

.PADDING5 {
	padding: 5px !important;
}

.PADDING10 {
	padding: 10px !important;
}

.PADDING20 {
	padding: 20px !important;
}

.PADDING30 {
	padding: 30px !important;
}

.PADDING40 {
	padding: 40px !important;
}

.PADDING50 {
	padding: 50px !important;
}

.W100per {
	width: 100% !important;
}

.W50per {
	width: 50% !important;
}

.W150 {
	width: 150px !important;
}

.W140 {
	width: 140px !important;
}

.W100 {
	width: 100px !important;
}

.W50 {
	width: 50px !important;
}

.W70 {
	width: 70px !important;
}

.listNone {
	display: none !important;
}

.listBlock {
	display: block !important;
}

/* 루트 변수 사용. 사용시, var(변수명) 입력 */
:root {
	--color-white: #ffffff;
	--color-sub2: #0d0f1a;
	--color-deepgrey: #3d3f45;
	--color-deepgrey2: #202020;
	--color-darkgrey: #454545;
	--color-grey: #6f6f6f;
	--color-grey2: #6c6c6c;
	--color-midgrey: #999999;
	--color-lightgrey: #d8d8d8;
	--color-lightgrey2: #ececec;
	--color-lightpuple: #f7f7fd;
	--color-black-alpha70: rgba(0, 0, 0, 0.7);
	--color-midgrey2: #c5c5c5;
	--color-main-bg: #06070d;

	--color-video: #18a87d;
	--color-video-alpha15: rgba(24, 168, 125, 0.15);
	--color-video-alpha50: rgba(24, 168, 125, 0.5);
	--color-sound: #5377f8;
	--color-sound-alpha50: rgba(83, 119, 248, 0.5);

	--color-white-alpha80: rgba(255, 255, 255, 0.8);
	--color-white-alpha50: rgba(255, 255, 255, 0.5);
	--color-white-alpha20: rgba(255, 255, 255, 0.2);
	--color-white-alpha10: rgba(255, 255, 255, 0.1);
	--color-line-darkgrey: #51535d;

	--color-sub-normal: #262834;
	--color-sub-alpha50: rgba(38, 40, 52, 0.5);
	--color-sub-alpha70: rgba(30, 32, 43, 0.7);
	--color-sub-alpha90: rgba(30, 32, 43, 0.9);

	--color-sub-alpha40: rgba(38, 40, 52, 0.4);

	/* back
	--color-sub2:#353849;
	*/
}